import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vad skulle du göra om du vann 237 miljoner ?
Igår så var det en svensk spelare som vann 237 miljoner kronor på Lotto. Det här är den största spelvinsten som någonsin har betalats ut inom Sverige.`}</p>
    <p>{`Men spelar du Mega Fortune så kommer snart jackpot vinsten att vara din. Just nu så kan man vinna 28 miljoner igenom att spela Mega Fortune hos MrGreen.
Vad skulle man inte göra om man vann 237 miljoner kronor, man skulle till exempel kunna köpa 94800 iPad mini, 56 stycken Spyker C12 Zagato eller 17500000 liter Coca Cola.`}</p>
    <p>{`Eller skulle man nu inte vilja spendera alla pengarna så kunde man stoppa alla på banken och leva på 7 miljoner kronor om året igenom den ränta man kommer att få.`}</p>
    <p>{`Ta chansen att leva resten av livet på ränta här`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      